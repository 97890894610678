
import ReviewDetailList from './components/ReviewDetailList'

export default {
    name: 'Review-Detail',
    components: {
        ReviewDetailList
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            query: {},
        }
    },
    computed: {},
    watch: {},
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        initialSet() {
            this.query = this.$route.query
        }
    },
}
