
export default {
    name: '',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            img: {
                list: []
            },
            swiperCompo: {
                slidesPerView: 'auto',
                spaceBetween: 6,
                watchOverflow: true
            },
            biz: {},
            popupCompo: {
                visible: false
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            immediate: true,
            deep: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            // 评论视频/图片
            const commentVideo = this.info.commentVideo || []
            const commentPics = this.info.commentPics || []
            let filterVideo = []
            let list = []
            commentPics.forEach(c => {
                if(!c.includes('mp4')) {
                    list.push({
                        url: c,
                    })
                } else {
                    filterVideo.push({
                        url: c
                    })
                }
            })
            const videoArr = [...commentVideo, ...filterVideo]
            videoArr.forEach(c => {
                list.unshift({
                    url: `${c.url}`,
                    video: true,
                })
            })
            this.img.list = list
            this.img.hasList = !!this.img.list.length
        },
        // 预览
        handlePreview(obj) {
            const commentPics = this.info.commentPics || []
            const index = commentPics.findIndex(p => p == obj.url) || 0
            this.$ImagePreview({
                images: commentPics,
                startPosition: index
            })
        },
        // 视频预览
        handleVideo(obj) {
            this.biz.url = obj.url
            this.popupCompo.visible = true
        }
    },
}
