
import ReviewCenterImg from './components/ReviewCenterImg'
import ReviewCenterReply from './components/ReviewCenterReply'
import ReviewCenterHelp from './components/ReviewCenterHelp'

export default {
    name: 'ReviewCenter',
    layout: 'main-head',
    components: {
        ReviewCenterImg,
        ReviewCenterReply,
        ReviewCenterHelp
    },
    async asyncData({$api, cookies, ...context}) {
        const response = await $api.review.getCenterReview({
            pageSize: 10,
            pageNum: 1,
            sortColumn: 'gmtCreate:DESC'
        }, false);
        const res = response?.result || {};
        let list = res.list || []
        list.forEach(p => {
            p.showPopover = false
            p.hasSpec = p.specList && !!p.specList.length
        })
        let info = {
            ...res,
            list
        }
        info.rate = Number((res.avgStar / 10000).toFixed(1))
        return {
            info,
            reviewList: list
        }
    },
    props: {},
    data() {
        return {
            showPopover: false,
            loading: false,
            info: {},
            reviewList: [],
            rec: {
                visible: false,
                list: []
            },
            page: {
                pageSize: 10,
                pageNum: 1
            },
            options: {
                list: []
            },
            preview: {
                url: '',
                hasVideo: false
            },
            params: {
                sortColumn: 'gmtCreate:DESC'
            },
            productCompo: {
                hasColumn: true,
                hasCustomize: false,
                hasQuantity: false,
                hasRate: true,
                hasOrg: false ,
                hasRateNum: true,
                hasTag: false,
                hasJumpProduct: true,
                hasPropIcon:true,
                hasSkuImg:true,
                img: {
                    width: '165px',
                    height: 'auto'
                }
            }
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        this.getRecAjax()
    },
    methods: {
        // 获取评论
        getCenterReviewAjax() {
            this.$api.review.getCenterReview({
                ...this.page,
                ...this.params
            }).then(response => {
                const res = response.result || {}
                this.info = res
                this.setData()
            })
        },
        setData() {
            let list = this.info.list || []
            list.forEach(p => {
                p.showPopover = false
                p.hasSpec = p.specList && !!p.specList.length
            })
            this.reviewList = this.page.pageNum > 1 ? [...this.reviewList, ...list] : list
            this.info.rate = Number((this.info.avgStar / 10000).toFixed(1))
            this.loading = false
        },
        getRecAjax() {
            this.$api.common.getRecV5Common({
                index: 6,
                pageSize: 20,
                pageNum: 1,
            },false).then(response => {
                const res = response?.result || [];
                this.rec.list = res
                this.rec.visible = !!this.rec.list.length
            })
        },
        changeSort(e) {
            this.page.pageNum = 1
            this.getCenterReviewAjax()
        },
        handleProduct(obj) {
            window.open(`/${obj.seoUrl}`)
        },
        handleBtn() {
            if(this.page.pageNum < this.info.totalPage) {
                this.loading = true
                this.page.pageNum += 1
                this.getCenterReviewAjax()
            }
        },
        loadMore(e) {
            const intersection = e && (e[0] || {}) || {};
            const isIntersecting = intersection.isIntersecting
            if(this.page.pageNum < this.info.totalPage && isIntersecting && this.page.pageNum < 4) {
                // this.loading = true
                // this.page.pageNum += 1
                // this.getCenterReviewAjax()
            }
        },
        // SEO HEAD
        setHead() {
            const list = this.info.list || []
            const first = list[0]|| {};
            const pic = first.pic || '';
            const head = this.$ctxApi.seoHead.initial({
                title: this.info.seoTitle,
                keywords: this.info.seoRemarks,
                description: this.info.seoRemarks,
                image: pic
            });
            return head
        },
        // 初始化
        initialSet() {
            this.options.list = [{
                text: this.$translate('Most recent'),
                value: 'gmtCreate:DESC'
            }, {
                text: this.$translate('Most helpful'),
                value: 'helpFul:DESC'
            }, {
                text: this.$translate('Highest rating'),
                value: 'commentStar:DESC'
            }, {
                text: this.$translate('Lowest rating'),
                value: 'commentStar:ASC'
            }, {
                text: this.$translate('Pictures first'),
                value: 'commentPics:DESC'
            }, {
                text: this.$translate('Video first'),
                value: 'commentVideo:DESC'
            }]
        }
    },
}
