
export default {
    name: 'Review-DetailList',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        query: {
            type: Object,
        }
    },
    data() {
        return {
            info: {
                length: 0,
                list: []
            },
            share: {
                visible: false,
                commentId: ''
            },
            shareCompo: {
                visible: false
            },
            popup: {
                visible: false,
                url: ''
            },
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getDetailAjax()
    },
    methods: {
        // 获取订单数据
        getDetailAjax() {
            this.$api.review.getOrderReviewInfo({
                orderId: this.query.order_id,
                orderSeq: this.query.order_seq
            }).then(response => {
                const res = response.result || {};
                this.info.list = res.packageList || []
                this.info.length = this.info.list.length
                this.setData()
                this.getReviewConfigAjax()
            })
        },
        setData() {
            this.info.list.forEach((item, i) => {
                item.orderItemCommentList.forEach((list, cI) => {
                    list.orderItem.rsProp = ''
                    if(list.orderItem.propList) {
                        let propArr = [];
                        list.orderItem.propList.forEach(c => {
                            propArr.push(c.specVal)
                        })
                        list.orderItem.rsProp = propArr.join('/')
                    }
                    if(list.commentInfo) {
                        let gmtCreate = list.commentInfo.gmtCreate;
                        list.commentInfo.rsGmtCreate = `${this.$dayjs(gmtCreate).format('MMM')} ${this.$dayjs(gmtCreate).format('D')}, ${this.$dayjs(gmtCreate).format('YYYY')}`
                    }
                })
                item.packageComment.rsLogisticsSatisfied = item.packageComment.logisticsSatisfied ? 'Satisfied' : 'Unsatisfied'
                item.packageComment.rsLogisticsKeyword = item.packageComment.logisticsKeyword.split(',')
                if(this.info.length > 1) {
                    item.title = `${this.$translate('Package')} ${i + 1}`
                }
            })
        },
        setCommunityData(obj) { // 分享社区参数
            let product = [];
            let media = [];
            let content = '';
            let type = 1;
            if(obj.orderItem) {
                product.push(obj.orderItem)
            }
            if(obj.commentInfo) {
                if(obj.commentInfo.commentPics) {
                    obj.commentInfo.commentPics.forEach(c => {
                        media.push({
                            imageUrl: c
                        })
                    })
                    type = 1
                }
                if(obj.commentInfo.commentVideo) {
                    obj.commentInfo.commentVideo.forEach(c => {
                        media.push({
                            videoUrl: c.url
                        })
                    })
                    type = 2
                }
                if(obj.commentInfo.commentPics || obj.commentInfo.commentVideo) {
                    type = 3
                }
                content = obj.commentInfo.logisticsRemarks || ''
            }
            this.shareCompo.community = {
                product: product, // 商品id集合
                media: media, // 媒体
                content: content, // 评价内容
                type: type, // 媒体类型 1 图文 2 纯视频 3 图文+视频
            }
        },
        // 获取评价配置信息
        getReviewConfigAjax() {
            this.$api.review.getOrderReviewConfig({
                orderId: this.query.order_id,
                orderSeq: this.query.order_seq,
            }).then(response => {
                const res = response.result || {};
                this.share.commissionPercentage = res.commissionPercentage
            })
        },
        // 图片预览
        handleImg(obj) {
            this.$ImagePreview({
                images: obj.list.commentInfo.commentPics,
                startPosition: obj.cI
            })
        },
        // 视频播放
        handleVideo(obj) {
            this.popup.visible = true
            this.popup.url = obj.c.url
            let clear = setTimeout(() => {
                let ref = this.$refs['videoPreview'] || [];
                if(ref) {
                    ref.paused == true ? ref.play() : ref.pause()
                }
                clearTimeout(clear)
            }, 100)
        },
        // 提示
        handleTip() {
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '169113173796506vXD2Qa'
                }
            })
        },
        // 分享
        handleShare(obj) {
            this.setCommunityData(obj)
            let commentInfo = obj.commentInfo || {};
            let origin = location.origin;
            let userId = localStorage.getItem('userId') || localStorage.getItem('loginUserId') || localStorage.getItem('channelUserId') || '';
            this.share.commentId = commentInfo.id
            this.share.comment = commentInfo.commentContent
            this.share.pic = commentInfo.mainPic

            let queryArr = [
                `shareOrderId=${this.query.order_seq}`,
                `shareCommentId=${this.share.commentId}`,
                `sharePlatform=H5`,
                `shareUserId=${userId}`,
                `accessTime=${new Date().getTime()}`
            ];
            this.shareCompo.shareInfo = [{
                shareOrderId: this.query.order_seq,
                shareCommentId: this.share.commentId,
                sharePlatform: 'H5',
                shareUserId: userId,
                accessTime: new Date().getTime()
            }]
            let queryStr = queryArr.join('&');
            this.shareCompo.url = `${origin}/comment/share?${queryStr}`
            this.shareCompo.url = this.shareCompo.url.replace('m.yfn', 'www.yfn');
            this.shareCompo.url = this.shareCompo.url.replace('mmall.', 'pcmall.');
            this.shareCompo.title = this.$translate('I want to share the review with you! Click it out')
            this.shareCompo.description = this.share.comment
            this.shareCompo.img = this.share.pic
            this.shareCompo.hasTip = true
            this.shareCompo.visible = true
        },
    },
}
