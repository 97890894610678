
export default {
    name: 'ReviewCenterReply',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            reply: {
                replyText: '',
                replyStatus: '',
                replyMore: ''
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.reply.replyContent = this.info.replyContent
            if(this.reply.replyContent) {
                const replyLength = this.reply.replyContent.length
                this.reply.replyMore = replyLength > 80
                this.reply.replyStatus = false
                this.reply.replyText = `${this.reply.replyContent.substr(0, 80)}${replyLength > 80 ? '...' : ''}`
            }
        },
        handleMore() {
            this.reply.replyStatus = !this.reply.replyStatus
            const length = this.reply.replyStatus ? 10000 : 80
            this.reply.replyText = `${this.reply.replyContent.substr(0, length)}${!this.reply.replyStatus ? '...' : ''}`
        },
    },
}
