
export default {
    name: 'Review-EditSet',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        middleEmit: Object,
        info: Object,
        query: Object,
        user: Object
    },
    data() {
        return {
            active: 0,
            form: {},
            check: {
                hasCheck: true,
                pointClass: [],
                error: []
            },
            review: {
                anonymous: false
            },
            params: {}
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
        // 提交评论接口
        setOrderCommentAjax() {
            const url = this.query.from ? 'setOrderCommentEmail' : 'setOrderComment';
            this.$api.review[url]({
                data: JSON.stringify(this.params)
            }).then(response => {
                const res = response.result || {};
                if(!res.repeatStatus) {
                    this.$router.push({
                        name: 'Review-Success',
                        query: {
                            order_id: this.query.order_id,
                            order_seq: this.params.orderSeq
                        }
                    })
                } else {
                    this.$Dialog.confirm({
                        message: `${this.$translate(`This order has been reviewed, please do not repeat review`)}`,
                        cancelButtonText: this.$translate('Cancel'),
                        confirmButtonText: this.$translate('Check My Review'),
                        cancelButtonColor: '#999',
                        confirmButtonColor: '#333',
                    }).then(() => {
                    this.$router.push({
                        name: 'Review-Detail',
                        query: {
                            order_id: this.query.order_id,
                            order_seq: this.params.orderSeq
                        }
                    })
                    }).catch(() => {});
                }
            })
        },
        // 匿名
        handleChoose() {
            this.review.anonymous = !this.review.anonymous
        },
        // 提交按钮
        handleSubmit() {
            let form = this.middleEmit.fn('getForm');
            this.form = form.form;
            this.checkForm()
            this.setParams()
            if(this.check.hasCheck) {
                this.setOrderCommentAjax()
            } else {
                if(!this.check.hasCheck) {
                    this.$Toast(this.check.error[0])
                    this.scrollToFn()
                }
            }
        },
        scrollToFn() {
            this.middleEmit.fn('scrollToFn', `.${this.check.pointClass[0]}`)
        },
        // 校验
        checkForm() {
            let listArr = this.info.rs.list;
            this.check.hasCheck = true
            this.check.pointClass = []
            this.check.error = []
            listArr.forEach((item, i) => {
                item.yfnOrderItem.forEach((list, cIndex) => {
                    list.hasCheck = !!this.form[list.filed.commentStar]
                    if(!list.hasCheck) {
                        this.check.error.push(list.filed.starError)
                    }
                })
                // 提示
                let logisticsSatisfied = this.form[item.filed.logisticsSatisfied];
                if(logisticsSatisfied === undefined) {
                    this.check.error.push(item.filed.allSatisfiedError)
                }
                let hasKeywords = true;
                if(!this.form[item.filed.logisticsKeyword]) {
                    hasKeywords = false
                    if(logisticsSatisfied) {
                        this.check.error.push(item.filed.satisfiedError)
                    } else {
                        this.check.error.push(item.filed.unsatisfiedError)
                    }
                }
                if(this.form[item.filed.logisticsKeyword] && !this.form[item.filed.logisticsKeyword].length) {
                    hasKeywords = false
                    if(logisticsSatisfied) {
                        this.check.error.push(item.filed.satisfiedError)
                    } else {
                        this.check.error.push(item.filed.unsatisfiedError)
                    }
                }
                item.hasCheck = !!(logisticsSatisfied !== undefined && hasKeywords)
            })
            // 校验状态
            listArr.forEach(item => {
                item.yfnOrderItem.forEach((list, cIndex) => {
                    if(!list.hasCheck) {
                        this.check.hasCheck = false
                        this.check.pointClass.push(list.pointClass)
                    }
                })
                if(!item.hasCheck) {
                    this.check.hasCheck = false
                    this.check.pointClass.push(item.pointClass)
                }
            })
        },
        // 设置提交评价参数
        setParams() {
            let key = '';
            let listArr = this.info.rs.list;
            let packageList = [];
            let formCommentKey = ['commentStar', 'commentStarDesc', 'commentContent', 'commentPics', 'commentVideo'];
            let formLogKey = ['logisticsSatisfied', 'logisticsKeyword', 'logisticsRemarks'];
            listArr.forEach((item, i) => {
                let orderItemCommentList = [];
                let packageComment = {};
                item.yfnOrderItem.forEach((list, cIndex) => {
                    key = `${i}${cIndex}`
                    // 评论参数
                    let commentInfo = {
                        spuId: list.yfnSpuId,
                        skuId: list.yfnSkuId,
                        mainPic: list.mainPic,
                    };
                    formCommentKey.forEach(c => {
                        if(this.form[`${c}${key}`]) {
                            commentInfo[c] = this.form[`${c}${key}`]
                        }
                    })
                    orderItemCommentList.push({
                        orderItemId: list.id,
                        commentInfo
                    })
                })
                // 物流参数
                formLogKey.forEach(c => {
                    if(this.form[`${c}${i}`] !== undefined) {
                        packageComment[c] = this.form[`${c}${i}`]
                        if(c == 'logisticsKeyword') {
                            packageComment[c] = this.form[`${c}${i}`].join(',')
                        }
                    }
                })
                packageList.push({
                    packageId: item.id,
                    orderItemCommentList,
                    packageComment,
                })
            })
            this.params.orderId = this.info.id
            this.params.orderSeq = this.info.originOrderId
            this.params.packageList = packageList
            this.params.anonymous = this.review.anonymous || false
        }
    },
}
