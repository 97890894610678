
export default {
    name: 'Review-EditForm',
    props: {
        info: Object,
        middleEmit: Object
    },
    data() {
        return {
            log: {
                good: [],
                bad: [],
            },
            form: {},
            target: {},
            popup: {
                visible: false,
                url: ''
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        setData() {
            if(!this.hasWatch) {
                let list = this.info.rs.list || [];
                list.forEach(p => {
                    p.yfnOrderItem.forEach(c => {
                        c.rsProp = ''
                        if(c.propList) {
                            let propArr = [];
                            c.propList.forEach(c => {
                                propArr.push(c.specVal)
                            })
                            c.rsProp = propArr.join('/')
                        }
                        c?.filed?.commentStar & this.$set(this.form, c.filed.commentStar, 5)
                        this.hasWatch = true
                    })
                    this.$set(this.form, p.filed.logisticsRemarks, '')
                })
            }
        },
        // 上传
        handleUpload(obj) {
            this.target = obj
        },
        uploadAfter(e) { // 上传阿里云
            this.setUploadData({
                ...e.oss
            })
        },
        setUploadData(obj) { // 上传数据设置
            let key = `${this.target.i}${this.target.cIndex}`;
            let keyForm = '';
            let keyVal = [];
            let videoKey = `commentVideo${key}`;
            let picKey = `commentPics${key}`;
            let mediaLength = this.form.mediaLength || 0;
            let videoLength = this.form.videoLength || 0;
            let photoLength = this.form.photoLength || 0;
            if(obj.reUrl.includes('mp4')) {
                keyForm = videoKey
                keyVal = [{
                    url: obj.reUrl
                }]
                videoLength += 1
            } else {
                keyForm = picKey
                keyVal = [obj.reUrl]
                photoLength += 1
            }
            // 图片/视频数量数据
            mediaLength += 1
            let showMediaLength = photoLength > 2 ? 2 : 0
            if(videoLength) {
                showMediaLength += 1
            }
            if(showMediaLength > 3) {
                showMediaLength = 3
            }
            let percentage = Math.ceil((showMediaLength / 3) * 100)
            this.$set(this.form, 'percentage', percentage)
            this.$set(this.form, 'showMediaLength', showMediaLength)
            this.$set(this.form, 'mediaLength', mediaLength)
            this.$set(this.form, 'videoLength', videoLength)
            this.$set(this.form, 'photoLength', photoLength)
            this.$set(this.form, keyForm, [...(this.form[keyForm] || []), ...keyVal])
            this.setUploadLength({key})
        },
        // 视频/图片数量
        setUploadLength(obj) {
            let videoKey = `commentVideo${obj.key}`;
            let picKey = `commentPics${obj.key}`;
            let length = 0;
            [videoKey, picKey].forEach(item => {
                length += (this.form[item] || []).length
            })
            this.$set(this.form, `uploadLength${obj.key}`, length)
            this.middleEmit.fn('changeMedia', this.form)
        },
        // 删除上传视频/图片
        deleteUpload(obj) {
            let type = obj.type; // 1 视频删除 2 图片删除
            let key = `${obj.i}${obj.cIndex}`;
            let keyForm = '';
            let videoKey = `commentVideo${key}`;
            let picKey = `commentPics${key}`;
            let mediaLength = this.form.mediaLength;
            if(type == 1) {
                keyForm = videoKey
            } else {
                keyForm = picKey
            }
            this.form[keyForm].splice(obj.gIndex, 1)
            mediaLength -= 1
            // 图片/视频数量数据
            if(type == 1) {
                this.form.videoLength -= 1
            } else {
                this.form.photoLength -= 1
            }
            this.form.showMediaLength = this.form.photoLength > 2 ? 2 : 0
            if(this.form.videoLength) {
                this.form.showMediaLength += 1
            }
            if(this.form.showMediaLength > 3) {
                this.form.showMediaLength = 3
            }
            this.form.mediaLength = mediaLength
            let percentage = Math.ceil((this.form.showMediaLength / 3) * 100)
            this.form.percentage = percentage
            this.setUploadLength({key})
        },
        // 图片预览
        handlePreview(obj) {
            this.$ImagePreview({
                images: obj.list,
                startPosition: obj.$i
            });
        },
        // 视频播放
        handleVideo(obj) {
            this.popup.visible = true
            this.popup.url = obj.url
            this.$fnApi.debounce(() => {
                let ref = this.$refs['previewVideo'];
                ref && ref.play()
            }, 300)
        },
        // 选项change
        changeLog(obj) {
            if(this.form[obj.filed.logisticsKeyword]) {
                this.form[obj.filed.logisticsKeyword] = []
            }
            // this.middleEmit.fn('scrollToFn', `.${obj.pointClass}`)
        },
        // remark
        showRemark(val, obj) {
            if(val === undefined) {
                return false
            }
            const hasOthers = val.includes(this.$translate('Others'));
            !hasOthers && (this.form[obj.filed.logisticsRemarks] = '')
            return hasOthers
        },
        // 星级评价文案
        setStarStatus(val) {
            let arr = [{
                min: 0,
                max: 2,
                text: 'Very Dissatisfied'
            }, {
                min: 2,
                max: 3,
                text: 'Dissatisfied'
            }, {
                min: 3,
                max: 4,
                text: 'Fair'
            }, {
                min: 4,
                max: 5,
                text: 'Satisfied'
            }, {
                min: 5,
                max: 6,
                text: 'Very Satisfied'
            }];
            let r = {
                text: 'Tap to rate',
                class: 'ReviewEditForm-rate--none'
            };
            let rArr = arr.filter(item => {
                return val >= item.min && val < item.max
            });
            if(val) {
                r = rArr[0]
                r.class = 'ReviewEditForm-rate--normal'
            }
            r.text = this.$translate(r.text)
            return r
        },
        // 初始化
        initialSet() {
            this.log.good = [{
                text: 'Good logistics service attitude'
            }, {
                text: 'Fast delivery'
            }, {
                text: 'Good packing service'
            }, {
                text: 'Delivery on time'
            }, {
                text: 'Others',
                type: 1
            }]
            this.log.good.forEach(v => {
                v.text = this.$translate(v.text)
            })
            this.log.bad = [{
                text: 'Delivery time is too long'
            }, {
                text: 'Bad logistics service attitude'
            }, {
                text: `Don't deliver till home`
            }, {
                text: `Don't deliver at scheduled time`
            }, {
                text: `Bad packing service`
            }, {
                text: 'Others',
                type: 1
            }]
            this.log.bad.forEach(v => {
                v.text = this.$translate(v.text)
            })
        }
    },
}
