
import fnApi from '/utils/common/methods'
import ReviewEditForm from './components/ReviewEditForm'
import ReviewEditSet from './components/ReviewEditSet'

export default {
    name: 'Review-Edit',
    components: {
        ReviewEditForm,
        ReviewEditSet
    },
    asyncData({api, cookies, ...context}) {},
    props: {},
    data() {
        return {
            skeleton: true,
            query: {},
            currency: {},
            country: {},
            fnApi: fnApi,
            middleEmit: {},
            hasLogin: false,
            info: {},
            form: {},
            user: {}
        }
    },
    head() {
        return this.setHead()
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.initialSet()
        if(this.query.from || this.query.form) {
            this.getOrderCommentStatusAjax()
        } else {
            this.getOrderDetailAjax()
        }
    },
    methods: {
        // 获取用户信息
        getUserAjax() {
            this.$api.user.getUseInfoNoLogin({
                id: this.info.yfnCustomerId
            }).then(response => {
                const res = response.result || {};
                this.user = res || {}
                this.user.dstName = this.$fnApi.dst.initial(this.user.nickName) || 't****o';
            })
        },
        // 获取订单评价状态
        getOrderCommentStatusAjax() {
            this.$api.review.getOrderReviewStatus({
                orderId: this.query.order_id,
                orderSeq: this.query.order_seq,
            }).then(response => {
                const res = response.result || {};
                let status = res.commentStatus;
                if(status) {
                    this.$router.push({
                        name: 'Review-Success',
                        query: {
                            order_id: this.query.order_id,
                            order_seq: this.query.order_sep,
                            review_status: 1
                        }
                    })
                } else {
                    this.getOrderDetailAjax()
                }
            })
        },
        // 获取订单数据
        getOrderDetailAjax() {
            this.$api.order.getOrderInfo({
                id: this.query.order_id,
            }).then(response => {
                const res = response.result || {};
                this.skeleton = false
                this.info = res
                this.info.rs = {}
                this.setData()
                this.getUserAjax()
            })
        },
        setData() {
            let list = [];
            let length = this.info.packageList.length;
            this.info.packageList.forEach((item, i) => {
                let yfnOrderItem = this.info.yfnOrderItem.filter(c => {
                    return item.orderItemId.includes(c.id)
                });
                list.push({
                    title: length > 1 ? `${this.$translate('Package')} ${i + 1}` : '',
                    ...item,
                    yfnOrderItem,
                })
            })
            this.info.rs.list = list
            this.setForm()
        },
        setForm() { // 表单form
            let listArr = this.info.rs.list;
            listArr.forEach((item, i) => {
                item.pointClass = `ReviewEditForm-point${i}`
                item.filed = {}
                item.filed.logisticsSatisfied = `logisticsSatisfied${i}`
                item.filed.logisticsKeyword = `logisticsKeyword${i}`
                item.filed.logisticsRemarks = `logisticsRemarks${i}`
                item.filed.allSatisfiedError = this.$translate('Please complete the logistics review')
                item.filed.satisfiedError = this.$translate('Please choose your satisfied reason')
                item.filed.unsatisfiedError = this.$translate('Please choose your unsatisfied reason')
                item.yfnOrderItem.forEach((list, c) => {
                    list.pointClass = `ReviewEditForm-point${i}${c}`
                    list.filed = {}
                    list.filed.commentStar = `commentStar${i}${c}`
                    list.filed.commentStarDesc = `commentStarDesc${i}${c}`
                    list.filed.commentPics = `commentPics${i}${c}`
                    list.filed.commentVideo = `commentVideo${i}${c}`
                    list.filed.uploadLength = `uploadLength${i}${c}`
                    list.filed.starError = this.$translate('Rating is required')
                })
            })
        },
        getForm() { // 获取表单
            let ref = this.$refs.ReviewEditForm;
            return ref;
        },
        // 滚动
        scrollToFn(ele) {
            this.$VueScrollTo.scrollTo(ele, '', {
                offset: 73
            })
        },
        // emit
        changeMedia(obj) { // emit-form表单 media change
            this.form = obj
        },
        // 返回
        handleBack() {
            this.$Dialog.confirm({
                message: `${this.$translate(`Are you sure to leave`)}?`,
                cancelButtonText: this.$translate('Yes'),
                confirmButtonText: this.$translate('No'),
                cancelButtonColor: '#999',
                confirmButtonColor: '#333',
            }).then(() => {
            }).catch(() => {
                this.$router.back()
            });
        },
        handleQuestion(){
            this.$router.push({
                name: 'Policy',
                query: {
                    id: '1691130000334mnzEudIx'
                }
            })
        },
        // SEO HEAD
        setHead() {
            const head = this.$ctxApi.seoHead.initial({
                title: this.$translate('Review'),
                keywords: this.$translate('Review'),
                description: this.$translate('Review'),
                image: 'https://cdn2.selleroa.com/yfn-upload/review/1694078645122.png'
            });
            return {
                ...head
            }
        },
        // 初始化
        initialSet() {
            this.query = this.$route.query
            this.currency = this.$storage.get('currency') || {}
            this.country = this.$storage.get('country') || {}
            this.hasLogin = this.$login()
            this.middleEmit.fn = (fn, options) => {
                return this[fn] && this[fn](options)
            }
        },
    },
}
