
export default {
    name: 'ReviewCenterHelp',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object
    },
    data() {
        return {
            help: {
                likeStatus: false,
                helpFul: 0
            }
        }
    },
    computed: {},
    watch: {
        info: {
            handler() {
                this.setData()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        setData() {
            this.help.likeStatus = this.info.likeStatus
            this.help.helpFul = this.info.helpFul
        },
        setAgree() {
            // 未登录跳转
            const hasLogin = this.$login()
            if(!hasLogin) {
                this.$router.push('/login')
                return
            }
            const likeStatus = !this.help.likeStatus
            this.$api.review.setAgree({
                spuCommentId: this.info.id,
                status: likeStatus
            }).then(response => {
                const res = response?.result
                if(res) {
                    this.help.likeStatus = !this.help.likeStatus
                    if(this.help.likeStatus) {
                        this.help.helpFul += 1
                    } else {
                        this.help.helpFul -= 1
                    }
                }
            })
        }
    },
}
